import React from 'react';

import { Icon } from '@components/Icon/Icon';
import { Text } from '@components/Text/Text';
import { Tiles } from '@components/Tiles/Tiles';
import { cdnStats } from '@siteData';

import styles from './HomeTiles.module.scss';
import DailyTrafficIcon from '@icons/dailyTraffic.svg';
import EngineeringIcon from '@icons/engineering.svg';
import VideosIcon from '@icons/videos.svg';

const items = [
  {
    icon: <EngineeringIcon />,
    value: `${cdnStats.yearsOnMarket} years`,
    title: 'of expertise in CDN',
    text: (
      <>
        Year after year, we remain financially stable with double-digit growth, while delivering
        satisfaction to thousands of&nbsp;clients.
      </>
    ),
  },
  {
    icon: <DailyTrafficIcon />,
    value: cdnStats.capacity,
    title: 'global network capacity',
    text: (
      <>
        Our regional network quality is consistently rated as exceptional compared to other leading
        CDN&nbsp;providers.
      </>
    ),
  },
  {
    icon: <VideosIcon />,
    value: cdnStats.dailyTrafficVideo,
    title: 'of video delivered daily',
    text: (
      <>
        Built with large media companies in mind, our CDN delivers live and VOD content on a
        massive&nbsp;scale.
      </>
    ),
  },
];

export const HomeTiles: React.FC = () => (
  <Tiles tabletVariant="2-columns" higherContrast>
    {items.map(({ icon, value, title, text }) => (
      <div key={title} className={styles.item}>
        <Icon className={styles.icon} size={30}>
          {icon}
        </Icon>
        <Text className="mb-2" color="blue700" display="block" size={32} tag="strong">
          {value}
        </Text>
        <Text className="mb-6" color="gray500" size={16} tag="p">
          {title}
        </Text>
        <Text size={16} tag="p">
          {text}
        </Text>
      </div>
    ))}
  </Tiles>
);
