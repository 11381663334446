import React from 'react';

import { CoreFeatures } from './CoreFeatures/CoreFeatures';
import { HomeHeader } from './HomeHeader/HomeHeader';
import { HomeReviews } from './HomeReviews/HomeReviews';
import { NetworkStats } from './NetworkStats/NetworkStats';
import { CtaPanel } from '@components/CtaPanel/CtaPanel';
import { Head } from '@components/Layout/Head';
import { routes } from '@routes';
import { cdnStats } from '@siteData';

interface IProps {
  title?: string;
  description?: string;
  noDescription?: boolean;
}

export const Home: React.FC<IProps> = ({ title, description, noDescription }) => (
  <>
    <Head
      canonical={routes.homePage}
      description={
        !noDescription
          ? description ??
            `${cdnStats.capacity} CDN with global coverage across ${cdnStats.continentsCount} continents. Accelerate your content delivery and reach your users from the edge with a 30-day free trial.`
          : undefined
      }
      title={title ?? 'Content Delivery Network (CDN)'}
    />
    <HomeHeader />
    <CoreFeatures />
    <NetworkStats />
    <HomeReviews />
    <CtaPanel className="mb-5" />
  </>
);
